import { noop } from "lodash";
import React, { useEffect } from "react";
import { View, Platform, ViewRoles } from "react-native";
import { Popover as RNPopover } from "react-native-popper";

import { isRef } from "src/common/utils/isRef";
import { tailwind } from "src/foundations/styles";

export type PopoverPlacement =
  | "bottom"
  | "bottom left"
  | "bottom right"
  | "top"
  | "top left"
  | "top right"
  | "left"
  | "left top"
  | "left bottom"
  | "right"
  | "right top"
  | "right bottom";

export type PopoverProps = {
  anchor: React.ReactElement | React.RefObject<any>;
  nativeID: string;
  isOpen?: boolean;
  onOpen?: () => void;
  onClose?: () => void;
  placement?: PopoverPlacement;
  closeOnEscapeKey?: boolean;
  closeOnAwayClick?: boolean;
  trapFocus?: boolean;
  restoreFocus?: boolean;
  accessibilityLabel?: string;
  accessibilityRole?: ViewRoles;
  accessibilityLabelledBy?: string;
  focusable?: boolean;
};

export const Popover: React.FC<PopoverProps> = (props) => {
  const {
    isOpen = false,
    onOpen = noop,
    onClose = noop,
    placement = "bottom",
    closeOnEscapeKey = true,
    closeOnAwayClick = true,
    trapFocus = true,
    restoreFocus = true,
    focusable = true,
  } = props;

  // If anchor is a ref, `react-native-popper` doesn't include these aria-* attributes. So we do it
  // ourselves.
  useEffect(() => {
    if (Platform.OS !== "web" || !isRef(props.anchor) || props.anchor.current === null) return;

    props.anchor.current.setAttribute("aria-haspopup", true);
    // Just run effect once.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // If anchor is a ref, `react-native-popper` doesn't include these aria-* attributes. So we do it
  // ourselves.
  useEffect(() => {
    if (Platform.OS !== "web" || !isRef(props.anchor) || props.anchor.current === null) return;

    props.anchor.current.setAttribute("aria-expanded", !!isOpen);
    props.anchor.current.setAttribute("aria-controls", isOpen ? props.nativeID : undefined);
  }, [props.anchor, isOpen, props.nativeID]);

  return (
    <RNPopover
      isOpen={isOpen}
      onOpenChange={(shouldOpen) => (shouldOpen ? onOpen() : onClose())}
      onRequestClose={onClose}
      trigger={props.anchor}
      on="press"
      placement={placement}
      isKeyboardDismissable={closeOnEscapeKey}
      shouldCloseOnOutsideClick={closeOnAwayClick}
      trapFocus={trapFocus}
      restoreFocus={restoreFocus}
      offset={8}
      shouldFlip={true}
      focusable={focusable}
    >
      <RNPopover.Backdrop />
      <RNPopover.Content accessibilityLabel={props.accessibilityLabel}>
        <View
          style={tailwind("bg-white rounded-lg p-2 shadow shadow-size-s")}
          nativeID={props.nativeID}
          accessibilityRole={props.accessibilityRole}
          // Seems to not get recognized by TS, but works fine in runtime.
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          accessibilityLabelledBy={props.accessibilityLabelledBy}
        >
          {props.children}
        </View>
      </RNPopover.Content>
    </RNPopover>
  );
};

export default Popover;
