import React from "react";
import { StyleProp, Text } from "react-native";
import { StyleSheet, TextStyle } from "react-native";

import Colors from "./colors.json";

type LabelProps = {
  weight?: "light" | "bold" | "normal";
  style?: StyleProp<TextStyle>;
  children: React.ReactNode;
  accessibilityRole?: "heading" | "button" | "label" | "link" | "listitem";
};

export default function Label({
  weight = "normal",
  style,
  children,
  accessibilityRole,
}: LabelProps) {
  return (
    <Text
      accessibilityRole={accessibilityRole || "label"}
      style={[labelStyles.label, labelStyles[weight], style]}
    >
      {children}
    </Text>
  );
}

const labelStyles = StyleSheet.create({
  bold: { fontFamily: "Monosten-bold" },
  light: { fontFamily: "Monosten-light" },
  normal: { fontFamily: "Monosten-regular" },
  label: {
    fontSize: 12,
    lineHeight: 22,
    letterSpacing: 1,
    textTransform: "uppercase",
    color: Colors.gray[800],
  },
});
