import React from "react";
import { StyleProp, Text } from "react-native";
import { StyleSheet, TextStyle } from "react-native";

import Colors from "./colors.json";

type SubtitleProps = {
  level?: 1 | 2;
  weight?: "light" | "bold" | "normal";
  style?: StyleProp<TextStyle>;
  children: React.ReactNode;
  accessibilityRole?: "heading" | "button" | "label" | "link" | "listitem";
  // TODO: added ability to specify another color
  color?: string;
  // TODO: Add this to foundation.
  /**
   * Only use as a last resort. https://testing-library.com/docs/queries/about/#priority
   */
  testID?: string;
};

export default function Subtitle({
  level = 1,
  weight = "normal",
  style,
  children,
  accessibilityRole,
  // TODO: added to override color if requested
  color,
  // TODO: Add this to foundation.
  testID,
}: SubtitleProps) {
  return (
    <Text
      // Add this to foundation
      testID={testID}
      accessibilityRole={accessibilityRole}
      style={[
        subtitleStyles[level],
        subtitleStyles[weight],
        style,
        // TODO: added to override color if requested
        color ? { color } : undefined,
      ]}
    >
      {children}
    </Text>
  );
}

const subtitleStyles = StyleSheet.create({
  bold: { fontFamily: "Inter-semibold" },
  light: { fontFamily: "Inter-light" },
  normal: { fontFamily: "Inter-regular" },
  1: {
    fontSize: 20,
    lineHeight: 28,
    color: Colors.gray[800],
  },
  2: {
    fontSize: 18,
    lineHeight: 26,
    letterSpacing: 0.5,
    color: Colors.gray[800],
  },
});
