import {
  Text,
  TouchableOpacity,
  StyleSheet,
  StyleProp,
  ViewStyle,
  GestureResponderEvent,
  View,
} from "react-native";

import { tailwind } from "../styles";

import LoadingSpinner from "./LoadingSpinner";
import Colors from "./colors.json";

// TODO: Make foundation support more sizes
type Size = "md" | "sm" | "xs";
// TODO: Make foundation support more variance
type Variant = "filled" | "outlined" | "ghost";

export type Props = {
  label: string;
  onPress?: (event: GestureResponderEvent) => void;
  style?: StyleProp<ViewStyle>;
  testID?: string;
  disabled?: boolean;
  loading?: boolean;
  // TODO: Make foundation support more variance
  /**
   * @deprecated Use variant instead.
   */
  secondary?: boolean;
  // TODO: Make foundation support more sizes
  size?: Size;
  // TODO: Make foundation support more variance
  variant?: Variant;
};

export default function Button(props: Props) {
  const styles = getStyles({
    secondary: props.secondary,
    disabled: props.disabled,
    size: props.size,
    // TODO: Make foundation support more variance
    variant: props.variant,
  });
  return (
    <TouchableOpacity
      onPress={props.onPress}
      disabled={props.disabled}
      testID={props.testID}
      style={[
        tailwind("rounded-full self-center justify-center"),
        // TODO: Make foundation support more variance
        (props.disabled && !props.loading) || props.variant === "ghost"
          ? tailwind("bg-gray-100")
          : // TODO: Make foundation support more variance
          props.secondary || props.variant === "outlined"
          ? // TODO: Should be transparent instead of white
            tailwind("bg-white")
          : tailwind("bg-gray-800"),
        styles.container,
        props.style,
      ]}
      accessibilityRole="button"
      accessibilityLabel={props.label}
    >
      <View style={tailwind("flex-row items-center justify-center")}>
        {props.loading && (
          <View style={tailwind("px-6 py-1")}>
            <LoadingSpinner size={24} />
          </View>
        )}
        {!props.loading && (
          <Text style={[styles.text, props.disabled && styles.textDisabled]}>{props.label}</Text>
        )}
      </View>
    </TouchableOpacity>
  );
}

const getStyles = ({
  secondary,
  disabled,
  size = "md",
  // TODO: Make foundation support more variance
  variant = "filled",
}: {
  secondary?: boolean;
  disabled?: boolean;
  // TODO: Make foundations support more sizes
  size?: Size;
  // TODO: Make foundations support more variance
  variant?: Variant;
}) =>
  StyleSheet.create({
    container: {
      // TODO: Medium padding-h should be 20px per spec. -1px to account for border
      // TODO: Make foundations support more sizes
      paddingHorizontal: size === "sm" ? 15 : size === "xs" ? 11 : 19,
      // TODO: Medium padding-v should be 18px per spec. -1px to account for border
      // TODO: Make foundations support more sizes
      paddingVertical: size === "sm" ? 11 : size === "xs" ? 7 : 17,
      // TODO: Border must always be present for consistent height between primary and secondary.
      borderWidth: 1,
      // TODO: Make foundation support more variance
      borderColor:
        (secondary || variant === "outlined") && !disabled ? Colors.gray[800] : "transparent",
    },
    text: {
      fontFamily: "Inter-semibold",
      // TODO: Medium should be 16px per spec.
      // TODO: Make foundations support more sizes
      fontSize: size === "sm" || size === "xs" ? 14 : 16,
      lineHeight: 16,
      // TODO: Make foundation support more variance
      color:
        secondary || variant === "outlined" || variant === "ghost"
          ? Colors.gray[800]
          : Colors.white.DEFAULT,
    },
    textDisabled: {
      color: Colors.gray[400],
    },
  });
