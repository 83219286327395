import { LegacyRef, useEffect, useRef } from "react";
import { StyleProp, View, ViewStyle, TouchableOpacity } from "react-native";

import { useHover } from "src/foundations/hooks/useHover";
import { tailwind } from "src/foundations/styles";
import { Checkbox } from "src/foundations/ui";

import { TableCell } from "./TableCell";

type TableRowProps = {
  style?: StyleProp<ViewStyle>;
  isHeader?: boolean;
  children: React.ReactNode;
  rowId?: number; // Unique id for row to be passed back with callbacks
  // Selectable props
  selectable?: boolean; // Whether or not this row can be selected using a checkbox
  selected?: boolean; // Whether or not this row should show selected styles
  selectDisabled?: boolean;
  onSelectChange?: () => void;
  // Hover props (for selectable rows)
  hoveredOverride?: boolean; // Force show hovered styles
  onHoverChange?: (hovered: boolean, rowId: number | undefined) => void;
  // Checkbox props
  checkboxAccessibilityLabel?: string;
  testID?: string;
  onRowPress?: () => void;
};

export function TableRow({
  style,
  isHeader,
  children,
  rowId,
  selectable,
  selected,
  selectDisabled,
  onSelectChange,
  hoveredOverride,
  onHoverChange,
  checkboxAccessibilityLabel,
  testID,
  onRowPress,
}: TableRowProps) {
  const [ref, hovered] = useHover();
  const hasMounted = useRef<boolean>(false);

  useEffect(() => {
    if (!hasMounted.current) {
      // Do not trigger hover changes for initial state
      hasMounted.current = true;
      return;
    }
    if (onHoverChange && typeof hovered === "boolean") {
      onHoverChange(hovered, rowId);
    }
  }, [hovered, onHoverChange, rowId]);

  const onCheckboxChange = () => {
    if (onSelectChange) {
      onSelectChange();
    }
  };

  const getStyle = () => {
    if (isHeader) {
      // Headers should have no special styles
      return style;
    }
    if (selected) {
      return tailwind("bg-green-lighter bg-opacity-40");
    }
    if (hoveredOverride || (selectable && hovered)) {
      return tailwind("bg-gray-50");
    }
    return style;
  };

  return onRowPress ? (
    <TouchableOpacity
      style={[style, getStyle()]}
      accessibilityRole="row"
      ref={ref as LegacyRef<TouchableOpacity>}
      testID={testID}
      onPress={onRowPress}
    >
      {selectable && (
        <TableCell style={tailwind("ml-6 mr-3")}>
          <Checkbox
            checked={selected}
            onChange={onCheckboxChange}
            label={checkboxAccessibilityLabel || "Select row"}
            visibleLabel={false}
            disabled={selectDisabled}
          />
        </TableCell>
      )}
      {children}
    </TouchableOpacity>
  ) : (
    <View
      // TODO: added position relative
      style={[style, tailwind("relative"), getStyle()]}
      accessibilityRole="row"
      ref={ref as LegacyRef<View>}
      testID={testID}
    >
      {selectable && (
        <TableCell style={tailwind("ml-6 mr-3")}>
          <Checkbox
            checked={selected}
            onChange={onCheckboxChange}
            label={checkboxAccessibilityLabel || "Select row"}
            visibleLabel={false}
            disabled={selectDisabled}
          />
        </TableCell>
      )}
      {children}
    </View>
  );
}
