// TODO: added useMemo
import React, { useState, useMemo } from "react";
import {
  StyleProp,
  ViewStyle,
  TextInput,
  View,
  NativeSyntheticEvent,
  TextInputFocusEventData,
  TextInputSubmitEditingEventData,
  TouchableOpacity, // TODO: added KeyboardTypeOptions
  KeyboardTypeOptions, // TODO: Support onKeyPress
  TextInputKeyPressEventData,
} from "react-native";

import { tailwind } from "../styles";

import { Label } from ".";
import colors from "./colors.json";
import Icon, { IconName } from "./icons";

// TODO: Export props so we can refer to it in other places (e.g DateInput)
export type TextInputProps = {
  style?: StyleProp<ViewStyle>;
  icon?: IconName;
  // TODO: Support onKeyPress
  onKeyPress?: (e: NativeSyntheticEvent<TextInputKeyPressEventData>) => void;
  onChangeText: (text: string) => void;
  onBlur?: ((e: NativeSyntheticEvent<TextInputFocusEventData>) => void) | undefined;
  onFocus?: ((e: NativeSyntheticEvent<TextInputFocusEventData>) => void) | undefined;
  value?: string;
  placeholder?: string;
  placeholderIsLabel?: boolean;
  // TODO: Support accessibilityRole
  accessibilityRole?: "textbox" | "searchbox";
  accessibilityLabel?: string;
  accessibilityDescribedBy?: string;
  focusable?: boolean;
  testID?: string;
  theme?: "white" | "gray";
  secureTextEntry?: boolean;
  onSubmitEditing?:
    | ((e: NativeSyntheticEvent<TextInputSubmitEditingEventData>) => void)
    | undefined;
  // TODO: Support max length.
  maxLength?: number;
  // TODO: support disabled
  disabled?: boolean;
  // TODO: add keyboard type
  keyboardType?: KeyboardTypeOptions;
  // TODO: add size property defaults md
  size?: "sm" | "md" | "lg";
  // TODO: Support width instead of style
  width?: number | string;
};

// TODO: enable users to use the component as a uncontrolled component.
export default React.forwardRef<TextInput, TextInputProps>(function CarbonTextInput(
  {
    style,
    icon,
    // TODO: Support onKeyPress
    onKeyPress,
    onChangeText,
    onFocus,
    onBlur,
    value,
    placeholder,
    // TODO: Support accessibilityRole
    accessibilityRole = "textbox",
    accessibilityLabel,
    accessibilityDescribedBy,
    focusable,
    testID,
    theme,
    secureTextEntry,
    onSubmitEditing,
    placeholderIsLabel,
    // TODO: Support max length.
    maxLength,
    // TODO: support disabled
    disabled,
    // TODO: support keyboardType
    keyboardType = "default",
    // TODO: support size option
    size = "md",
    // TODO: Support width instead of style
    width,
  },
  ref
) {
  const [height, setHeight] = useState(0);
  const [showSecureField, setShowSecureField] = useState(secureTextEntry || false);
  // TODO: fix icon size
  const iconHeight = height / 2;
  const choosenTheme = theme || "white";
  // TODO: added this memo function to encapsulate the height style
  const textInputHeightStyle = useMemo(() => {
    if (placeholderIsLabel) return tailwind("h-16");
    switch (size) {
      case "sm":
        return tailwind("h-8");
      case "md":
        return tailwind("h-10");
      case "lg":
        return { height: "52px" };
    }
  }, [placeholderIsLabel, size]);
  return (
    <View
      // TODO: Make the height match the design spec
      // style={[placeholderIsLabel ? tailwind("h-16") : tailwind("h-12"), style]}
      style={[textInputHeightStyle, style, { width }]}
      onLayout={(e) => {
        setHeight(e.nativeEvent.layout.height);
      }}
    >
      {placeholderIsLabel && !!value && (
        <Label
          weight="light"
          accessibilityRole="label"
          style={tailwind("absolute top-2 left-4 normal-case font-inter text-gray-400")}
        >
          {placeholder}
        </Label>
      )}
      <TextInput
        ref={ref}
        keyboardType={keyboardType}
        // TODO: Support onKeyPress
        onKeyPress={onKeyPress}
        onChangeText={onChangeText}
        onFocus={onFocus}
        onBlur={onBlur}
        value={value}
        focusable={focusable}
        placeholder={placeholder}
        // TODO: Support accessibilityRole
        accessibilityRole={accessibilityRole}
        accessibilityLabel={accessibilityLabel}
        // TS is wrong. accessibilityDesctibedBy is present and working just fine.
        // @ts-ignore
        accessibilityDescribedBy={accessibilityDescribedBy}
        secureTextEntry={showSecureField}
        onSubmitEditing={onSubmitEditing}
        placeholderTextColor={choosenTheme === "gray" ? colors.gray["800_50"] : undefined}
        style={[
          tailwind("w-full h-full px-4 py-2 items-center border-gray-100 border rounded-lg "),
          value && placeholderIsLabel ? tailwind("pt-4") : null,
          choosenTheme === "white" && tailwind("bg-white"),
          choosenTheme === "gray" && tailwind("bg-gray-100"),
          icon && height ? { paddingLeft: 24 + iconHeight } : null,
        ]}
        testID={testID}
        // TODO: Support max length.
        maxLength={maxLength}
        // TODO: support disabled
        editable={!disabled}
        selectTextOnFocus={!disabled}
      />
      {icon && iconHeight > 0 && (
        <View style={[tailwind("absolute"), { left: 12, top: height / 4 }]}>
          <Icon name={icon} size={iconHeight} />
        </View>
      )}
      {secureTextEntry && (
        <TouchableOpacity
          onPress={() => setShowSecureField((x) => !x)}
          style={[tailwind("absolute"), { right: 12, top: height / 4 }]}
        >
          <Icon name={showSecureField ? "eye-closed" : "eye-open"} size={iconHeight} />
        </TouchableOpacity>
      )}
    </View>
  );
});
